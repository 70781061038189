import ContainerMain from "src/pages/common/ContainerMain"
import Content from "src/pages/common/Content"
import image2 from "images/Product/Campaign/product-3-1b.png"
import { useFormatter } from "helpers/i18n"
import "./ModelConcept.scss"

export default function ModelConcept() {
  const { __ } = useFormatter()

  return (
    <>
      <div className="section TgCFPpSuJm-model">
        <ContainerMain>
          <div className="VWySdLLKwa">
            <div className="SXoRqGRTZg-2">
              <div className="FCjitlxKsb-2">
                <div
                  className="JDOVFGvteG"
                  dangerouslySetInnerHTML={{
                    __html: __({
                      defaultMessage:
                        "B. Propensity to Purchase - Model Concept",
                    }),
                  }}
                ></div>
              </div>
              <div className="FSKzfNpjVs">
                <div className="WHBaNESkqr">
                  <img className="BWtJTGJKzA" src={image2} alt="" />
                </div>
              </div>
            </div>
            <div className="WTKPvPwjkv right-content">
              <div className="AdwbmnHxqQ">
                <div className="mZogAJKRvX">
                  {__({ defaultMessage: "Objective" })}
                </div>
                <div className="VynXYuiuKj">
                  {__({
                    defaultMessage:
                      "Grow the revenue of certain products by reaching customers with the highest propensity to purchase them",
                  })}
                </div>
              </div>
              <div className="AdwbmnHxqQ">
                <div className="mZogAJKRvX">
                  {__({ defaultMessage: "Analytics" })}
                </div>
                <div className="VynXYuiuKj">
                  {__({
                    defaultMessage:
                      "AI analyzes entire customer database to find customers with the highest purchase propensity for the chosen product",
                  })}
                </div>
              </div>
              <div className="AdwbmnHxqQ">
                <div className="mZogAJKRvX">
                  {__({ defaultMessage: "Potential ROI" })}
                </div>
                <div className="VynXYuiuKj">
                  {__({ defaultMessage: "500% increase in conversion rate" })}
                </div>
              </div>
            </div>
          </div>
          <div className="sQEPOxcDwQ-2">
            {__(
              {
                defaultMessage:
                  "AI/Machine Learning Yielded <span>5x</span> Better Result than Human",
              },
              { span: (...chunks) => <span>{chunks}</span> }
            )}
          </div>
        </ContainerMain>
      </div>
    </>
  )
}
